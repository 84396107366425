import AppRouter from './routes/AppRouter';

import "./assets/styles/Main.scss"

/** Main Function */
function App() {
  return (
    <AppRouter />
  );
}

export default App;
